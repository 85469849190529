<template>
    <div class="seleTest xi" id="app">
        <hd-com></hd-com>
        <div class="content-wrap">
            <div class="content">
                <div class="top-div flex-start">
                    <div class="left-img"></div>
                    <div class="top-title">结果分析</div>
                </div>
                <!-- 选择测试人 -->
                <div class="userTestRes-div flex-center">
                    <div class="userTestRes-box  flex-center-columns ">
                        <div class="top-img flex-start">
                            <img src="../../assets/img/result.png" />
                            <div class="title">{{ adviseInfo.name }}</div>
                        </div>
                        <template v-if="adviseInfo.infos.length > 0">
                            <div class="top-3" v-if="showSummary == true">
                                <div class="top3-title">概述</div>
                                <div class="top3-desc" v-html="math(InfoSummarize.toString())"></div>
                            </div>
                            <div class="top-3" v-for="(item, index) in adviseInfo.infos" :key="index">
                                <div class="top3-title">{{ item.title }}</div>
                                <p class="top3-desc" v-html="math(item.info.toString())"></p>

                            </div>
                        </template>
                        <div class="top-3" v-else>
                            <div class="top3-title"></div>
                            <p class="top3-desc">暂无数据</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>

<script>
import hdCom from '../../components/header'
import ftCom from '../../components/footer'
import rtBar from "../../components/right";
import { Test } from '../../components/company/test';
import { replaceContent, checkNameAndPath, toSearchList, toDetailInfoOpenTab } from "../../utils/diseaseUtility";
export default {
    name: "selfTest",
    components: {
        hdCom,
        ftCom,
        rtBar
    },
    data() {
        var test = new Test(this.TokenClient, this.Services.Statics)
        return {
            testDomain: test,
            userInfo: this.$store.state.localData.userInfo,
            resId: this.$route.query.resId,
            answerUserId: this.$route.query.answerUserId,
            showSummary: this.$route.query.showSummary,
            userResult: {
                questionnaire: {
                    questionnaire: ''
                },
                questionnaireDetermines: [{
                    scores: 0
                }]
            },
            qingXiang: [],
            tiZhi: [],
            result: '',
            resultList: [],
            categories: [],
            series: [],
            InfoSummarize: "",
            adviseInfo: {
                name: '',
                infos: [{
                    info: ''
                }]
            },
        }
    },
    mounted() {
        this.showSummary = this.$route.query.showSummary
        this.$bus.$emit('isShowChangeSize');
        this.getDetailById()
    },
    created() {
        let _this = this;
        window.getPageInfo = _this.getPageInfo;
    },
    methods: {
        //跳转详情
        getPageInfo(name, type, hashKey) {
            if (hashKey == 'Search') {
                toSearchList(checkNameAndPath(type).typeName, name)
            } else {
                toDetailInfoOpenTab(checkNameAndPath(type).path, hashKey, type)
            }
        },
        //详情关联词
        math(info) {
            return replaceContent(info);
        },
        /**
         * 获取体质养生数据
         */
        getDetailById() {
            this.testDomain.getDetailById(this.resId,
                (res) => {
                    this.adviseInfo = res.data
                    this.adviseInfo.infos.forEach((item, index) => {
                        if (item.title == "概述") {
                            this.InfoSummarize = item.info;
                            // this.adviseInfo.infos.splice(index, 1);
                        }
                    });
                },
                (error) => {
                    console.log("请求失败!请刷新重试:", error);
                })
        }
    }
}
</script>

<style scoped>
.content-wrap {
    background: #fff;
}

.content {
    width: 1200px;
    margin: 0 auto;
    min-height: calc(100vh - 295px - 140px);
    padding: 30px 20px;
    background: url("../../assets/img/bg001.png") top;
    background-size: 100% 1050px;
}

.top-div {
    width: 80%;
    margin: 0 auto;
    margin-bottom: 30px;
}

.top-div .left-img {
    width: 24px;
    height: 24px;
    border: 5px solid #EBEEFD;
    background: linear-gradient(90deg, #4DB992, #81DFA7);
    box-shadow: 0px 3px 6px 0px rgba(18, 184, 149, 0.3);
    border-radius: 50%;
    margin-right: 10px;
}

.top-div .top-title {
    width: auto;
    height: 29px;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
    line-height: 30px;
}

.userTestRes-div .btn {
    width: 420px;
    height: 72px;
    background: linear-gradient(90deg, #03CDB4, #00A28E);
    box-shadow: 0px 8px 15px 0px rgba(1, 179, 157, 0.21);
    border-radius: 36px;
}

.btn .text {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
}

.addUser-div .w-18 {
    width: 80%;
}

.userTestRes-div {
    background: #fff;
    width: 80%;
    margin: 0 auto;
    height: auto;
    box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
    border-radius: 40px;
    padding: 30px 0;
}


.userTestRes-div .userTestRes-box {
    width: 90%;
}

.top-img {
    width: 100%;
    margin-bottom: 40px;
}

.top-img div.title {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #019582;
    margin-left: 10px;
}

.top-1,
.top-2,
.top-3,
.top-4 {
    width: 100%;
    margin-bottom: 40px;
}

.top-1 .top-3 {
    margin-bottom: 0;
}

.top-1 .top-title1 {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
}

.top-1 .top-title1 .tip {
    font-size: 18px;
}

.top-1 .left-tongJi {
    width: 30%;
    margin-right: 10%;
}

.desc-1,
.desc-2 {
    margin-right: 10px;
    border-bottom: 1px dashed;
}

.top-1 .left-tongJi img {
    width: 100%;
    height: 100%;
}

.top-1 .right-desc {
    width: 50%;
}

.top-1 .top-title1 .desc-1 {
    color: #019582;
}

.top-1 .top-title1 .desc-2 {
    color: #1A74E9;
}

.top-2 {
    min-height: 100px;
    background: #FFFFFF;
    border: 1px solid #6EC6BB;
    border-radius: 16px;
}

.top-2 .top2-desc {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
    padding: 20px;
}

.top-2 .top2-desc .mark {
    color: #1A74E9;
    border-bottom: 1px dashed;
    margin: 0 5px;

}

.top-2 .top2-desc i {
    font-size: 26px;
}

.top-3 {
    width: calc(100% - 40px);
    background: #F5F6F6;
    border-radius: 2px;
    padding: 20px;
}

.top-3 .top3-title {
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
}

.top-3 .top3-desc {
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #666666;
}

.top-3 .top3-desc /deep/ a {
    color: #019582 !important;
}

.top-3 .top3-title i {
    font-size: 26px;
}

.userTestRes-div .active {
    border: 1px solid #00CBB5 !important;
}

.userTestRes-div .item-div:hover {
    border: 1px solid #00CBB5 !important;
}

.userTestRes-div .item-div {
    width: 27.5%;
    height: 112px;
    margin-right: 2%;
    margin-bottom: 20px;
    border: 1px solid #E3E6E6;
    box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.14);
    border-radius: 30px;
    cursor: pointer;
    align-items: flex-start !important;
    padding: 10px 20px;
    position: relative;
}

.userTestRes-div .item-div:nth-child(3n) {
    margin-right: 0;
}

.userTestRes-div .userName {
    font-size: 28px;
    font-family: Source Han Sans CN;
    font-weight: 500
}

.userTestRes-div .item-div .addBtn {
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #009583;
}

.userTestRes-div .userInfo {
    color: #999999;
}

.userTestRes-div .topDel-div {
    position: absolute;
    top: 10px;
    right: 20px;
    color: #009583;
    cursor: pointer;
}
</style>
